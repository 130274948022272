import * as React from "react"

// markup
const NotFoundPage = () => {
    return (
        <div className="flex items-center justify-center mt-8">
            <h1 className="text-white text-3xl">404 Not Found</h1>
        </div>
    )
}

export default NotFoundPage
